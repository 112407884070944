import { ComponentProps, ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Card from '../Card'
import Value from './Value'

type RowItem = {
  label: string
  value: string | number | ReactElement
  action?: React.ReactNode
  url?: string
  type?: ComponentProps<typeof Value>['type']
}
type Props = {
  data: (RowItem | null)[]
} & React.ComponentProps<typeof Card>

const Component: React.FC<Props> = ({ data, ...cardProps }) => (
  <Card {...cardProps}>
    <Box>
      {data
        .filter((item): item is NonNullable<typeof item> => item !== null)
        .map(({ label, value, action, url, type = 'text' }) => (
          <Flex
            key={label}
            as="dl"
            direction={{ base: 'column', sm: 'row' }}
            px="6"
            py="4"
            _even={{ bg: 'gray.50' }}
          >
            <Box as="dt" minWidth="180px">
              {label}
            </Box>
            <Flex as="dd" flex="1" fontWeight="semibold">
              <Box whiteSpace="pre-wrap">
                <Value type={type} value={value} url={url ?? (value as string)} />
              </Box>
              {action !== undefined && (
                <Flex flex={1} justifyContent="flex-end" alignItems="center" ml={1}>
                  {action}
                </Flex>
              )}
            </Flex>
          </Flex>
        ))}
    </Box>
  </Card>
)

export default Component
