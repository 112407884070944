import Router from 'next/router'
import { makeOperation, Operation } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'
import { storage } from 'utils/storage/auth'
import { HOME, SIGN_OUT } from 'constants/path'
import { Kind } from 'graphql'
import {
  RefreshAuthTokenDocument,
  RefreshAuthTokenMutation,
  RefreshAuthTokenMutationVariables,
} from './query.gen-graphql'

const getOperationName = (operation: Operation) => {
  const { query } = operation
  for (const node of query.definitions) {
    if (node.kind === Kind.OPERATION_DEFINITION) {
      return node.name ? node.name.value : undefined
    }
  }
}

export const buildAuthExchange = () =>
  authExchange(async utils => {
    return {
      addAuthToOperation: operation => {
        const { token } = storage.load() ?? {}
        if (token === undefined) {
          return operation
        }
        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {}

        // ログを見やすくするために、operationNameをクエリパラメータに付与する
        const url = new URL(operation.context.url)
        const operationName = getOperationName(operation)
        url.searchParams.set('operationName', operationName ?? '')

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${token}`,
            },
          },
          url: url.toString(),
        })
      },
      didAuthError: error => {
        return error.graphQLErrors.some(e => e.extensions?.code === 'AUTHENTICATION_FAILED')
      },
      refreshAuth: async () => {
        const { refreshToken, token } = storage.load() ?? {}

        // refreshTokenがない場合は何もしない
        if (refreshToken === undefined) return

        // refreshToken がある場合は、Mutation.refreshAuthToken を実行して新しい認証トークンを取得する
        // `utils.mutate` は、そのままでは Authorization ヘッダーが未設定の状態なので、fetchOptions に Authorization ヘッダーを付与する
        const result = await utils.mutate<
          RefreshAuthTokenMutation,
          RefreshAuthTokenMutationVariables
        >(
          RefreshAuthTokenDocument,
          { input: { refreshToken } },
          { fetchOptions: { headers: { Authorization: `Bearer ${token}` } } },
        )

        if (result.data?.refreshAuthToken != null) {
          storage.save(result.data.refreshAuthToken)
        } else {
          const { pathname } = document.location
          const query = pathname !== HOME ? { redirect_to: pathname } : {}
          Router.replace({ pathname: SIGN_OUT, query })
        }
      },
    }
  })
