import * as Types from '../../../gen/graphql-type';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RefreshAuthTokenMutationVariables = Types.Exact<{
  input: Types.RefreshAuthTokenInput;
}>;


export type RefreshAuthTokenMutation = { refreshAuthToken: { __typename: 'AuthToken', token: string, refreshToken: string } | null };


export const RefreshAuthTokenDocument = gql`
    mutation RefreshAuthToken($input: RefreshAuthTokenInput!) {
  refreshAuthToken(input: $input) {
    __typename
    token
    refreshToken
  }
}
    `;

export function useRefreshAuthTokenMutation() {
  return Urql.useMutation<RefreshAuthTokenMutation, RefreshAuthTokenMutationVariables>(RefreshAuthTokenDocument);
};