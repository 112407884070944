import React from 'react'
import Link from 'next/link'
import { Heading, Box, Stack, Text, Button } from '@chakra-ui/react'
import Router from 'next/router'
import { useAuthState } from 'context/auth'

type ContainerProps = {
  title: string
  description?: string
}

type Props = {
  signedIn: boolean
} & ContainerProps

const Component: React.VFC<Props> = ({ title, description, signedIn }) => {
  return (
    <Stack textAlign="center" height="100%" spacing={4} justifyContent="center">
      <Box>
        <Heading size="lg" mb={4}>
          {title}
        </Heading>
        {description !== undefined && <Text color="deepgray">{description}</Text>}
      </Box>
      <Stack mx={20} mt={12} spacing={4}>
        <Button colorScheme="lightgray" fontSize="md" w="full" onClick={() => Router.reload()}>
          リロード
        </Button>
        {signedIn ? (
          <Link passHref href="/">
            <Button colorScheme="lightgray" fontSize="md" w="full">
              トップへ戻る
            </Button>
          </Link>
        ) : (
          <Link passHref href="/sign_up">
            <Button colorScheme="lightgray" fontSize="md" w="full">
              トップへ戻る
            </Button>
          </Link>
        )}
      </Stack>
    </Stack>
  )
}

const Container: React.FC<ContainerProps> = props => {
  const { signedIn } = useAuthState()

  return <Component {...props} signedIn={signedIn} />
}

export default Container
