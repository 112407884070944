import { createClient } from 'urql'
import { fetchExchange, ClientOptions } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import cache from './cache'
import { buildAuthExchange } from './auth'
import { subscription } from './subscription'
import { bugsnagExchange } from './bugsnag'

const exchanges =
  process.env.NODE_ENV === 'development'
    ? [
        devtoolsExchange,
        requestPolicyExchange({}),
        cache,
        buildAuthExchange(),
        fetchExchange,
        subscription,
      ]
    : [
        requestPolicyExchange({}),
        cache,
        bugsnagExchange,
        buildAuthExchange(),
        fetchExchange,
        subscription,
      ]

const client = () => {
  const config: ClientOptions = {
    url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    exchanges,
  }

  return createClient(config)
}

export default client
